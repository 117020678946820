import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RouterStateParams } from 'src/app/app-routing.serializer';
import { RoutesWithPathMatch, Utility } from 'src/app/shared/utility';
import { Announcement } from '../../api/models/announcement';
import { CoreActions } from '../core.actions';

// TODO: use route.data for this: https://angular.io/api/router/Route#data
const HIDE_ON_ROUTES: RoutesWithPathMatch = [
  { path: '/', pathMatch: 'full' },
  { path: '/prices', pathMatch: 'prefix' },
  { path: '/free', pathMatch: 'prefix' },
  { path: '/campaign', pathMatch: 'prefix' },
  { path: '/user/account_activation', pathMatch: 'prefix' },
];

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.sass'],
})
export class AnnouncementComponent implements OnInit {
  public announcement$: Observable<Announcement>;
  public hideAnnouncement$: Observable<boolean>;
  private routerState$: Observable<RouterStateParams>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new CoreActions.FetchAnnouncement());

    this.announcement$ = this.store.select(state => state.core.announcement);
    this.routerState$ = this.store.select(state => state.router.state);

    this.hideAnnouncement$ = this.routerState$.pipe(
      map(routerState => {
        return Utility.routeMatches(routerState?.url, HIDE_ON_ROUTES);
      }),
      startWith(true),
    );
  }

  close(announcementId: number) {
    this.store.dispatch(new CoreActions.CloseAnnouncement(announcementId));
  }
}
