import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Campaign } from '../models/campaign';
import { CampaignData } from '../models/campaign-data';
import { RedeemCouponResponse } from '../models/redeem-coupon-response';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  path = `${environment.apiPath}/v1`;

  constructor(private httpClient: HttpClient) {}

  getCampaign(campaignId: string): Observable<Campaign> {
    return this.httpClient
      .get<Campaign>(`${this.path}/campaigns/${campaignId}`)
      .pipe(catchError(this.handleError));
  }

  redeemCoupon(couponId: string): Observable<RedeemCouponResponse> {
    return this.httpClient
      .post<RedeemCouponResponse>(
        `${this.path}/campaigns/${couponId}/redeem`,
        {},
      )
      .pipe(catchError(this.handleError));
  }

  saveCampaignData(campaignData: CampaignData) {
    return this.httpClient
      .post<CampaignData>(`${this.path}/campaign_datas`, { utm: campaignData })
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.error.message}`;
    }
    return throwError(errorMessage);
  }
}
