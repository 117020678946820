<link rel="preconnect" href="https://ik.imagekit.io">
<app-navbar-with-logo></app-navbar-with-logo>
<div class="page-container page-container-desktop" role="main">
  <section class="is-position-relative mb--7px-desktop">
    <svg-wave
      class="is-position-absolute is-top-124px-mobile is-top-120px-tablet-only is-top-64px-desktop is-left-0 is-right-0 svg-wave-margin mt-1"></svg-wave>
    <h1
      class="is-position-absolute is-top-0 is-left-0-mobile is-left-0px-tablet is-right-0 has-text-centered-mobile is-size-2-mobile is-size-1-tablet is-line-height-110 is-family-secondary has-text-weight-heavy has-text-deep-sea-green">
      <ng-container i18n>Dein Online-Yogastudio</ng-container>
    </h1>
    <div
      class="is-hidden-mobile is-position-absolute is-top-70px is-left-0-mobile is-left-10px-tablet is-width-450px is-size-5-and-half has-text-kokoda">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>

    <img ngSrc="{{campaignImg}}" width="1170" height="468" alt="Campaign Image" priority
      ngSrcset="340w, 680w, 460w, 920w, 580w, 1140w, 730w, 1460w, 1170w, 2340w"
      sizes="(max-width: 730px) 100vw, (max-width: 1170px) 730px, 1170px"
      class="has-margin-top-133px-mobile has-margin-top-126px-tablet-only is-top-50px-desktop has-margin-top-80px-desktop mt-1" />

    <div class="is-hidden-tablet has-text-centered has-text-kokoda is-size-5-and-half py-4 px-6">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </section>

  <ng-template #text i18n>
    Übe Yoga mit den besten Lehrer:innen für mehr Balance,
    Stärke und innere Ruhe.
    Yoga für jeden Körper. Jederzeit.
  </ng-template>


  <div class="coupon-section mt-1">
    <h2 class="is-size-4 has-text-weight-bold has-text-centered mt-4 mb-2 is-campaign-subheader" i18n>Gib hier deinen Gutscheincode ein
    </h2>
  </div>
  <div class="coupon-section mt-1">
    <div class="is-campaign-form-item">
      <form class="mt-1 simple_form form" accept-charset="UTF-8" [formGroup]="campaignForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="is-width-100 example-form" [hideRequiredMarker]="true">
          <mat-label><ng-container i18n>Dein persönlicher Gutscheincode</ng-container></mat-label>
          <input matInput type="text" formControlName="coupon" class="input-field has-text-kokoda" (input)="onInputValueChanged()" />
          <mat-error *ngIf="couponField?.errors" class="has-text-error is-size-14px">
            <ng-container *ngIf="!campaignData?.coupon?.error_message" i18n>Der Code ist nicht gültig.</ng-container>
            <ng-container *ngIf="!campaignData?.coupon?.is_valid">{{campaignData?.coupon?.error_message}}</ng-container>
          </mat-error>
        </mat-form-field>
        <div class="centered">
          <input [disabled]="!couponField?.value || buttonDisabled" type="submit" value="Gutschein einlösen"
            class="button is-mandy-pink is-rounded has-text-weight-semibold mt-1 is-width-100-tablet is-width-100-mobile is-height-48px form-button"
            i18n-value />
        </div>

      </form>
    </div>
  </div>


  <section class="has-background-white is-block mb-5-and-half-mobile mb-6-tablet recommended-by-section">
    <h4 class="is-size-5 has-text-kokoda has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
      <ng-container i18n>
        Empfohlen von:
      </ng-container>
    </h4>
    <app-recommended-by></app-recommended-by>
  </section>
</div>