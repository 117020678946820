<div class="bookmarks-list-page" *ngIf="bookmarksList; else noList">
  <app-bookmarks-list-page-header [bookmarksList]="bookmarksList" class="is-block mb-3"></app-bookmarks-list-page-header>
  <app-sort-bookmarks-list-wrapper></app-sort-bookmarks-list-wrapper>
  <div cdkDropListGroup mixedCdkDragDrop [orientation]="(viewport$ | async) === 'mobile' ? 'vertical' : 'horizontal'"
    class="is-flex is-justify-content-space-around-touch is-flex-wrap-wrap m-minus-3" (dropped)="onDrop($event)">
    <!-- implemented as described here: https://github.com/rosejoe47/angular-mixed-cdk-drag-drop -->
    @for (teaser of bookmarksItems; track teaser.info.id) {
    <span cdkDropList mixedCdkDropList>
      <div cdkDrag class="m-3">
        <app-video-teaser [teaser]="teaser.info" [breadcrumb]="['Favoritenlisten', bookmarksList.title]" size="small" [draggable]="true" />
      </div>
    </span>
    }
  </div>

  <app-teaser-slider-empty-message *ngIf="!bookmarksItems.length" [displayHeadline]="false">
    <svg-heart-filled class="heart-icon is-block"></svg-heart-filled>
    <span class="light-text" i18n> Fülle diesen Platz mit deinen liebsten Yogavideos.</span>
    <app-button type="secondary" [href]="'/'" i18n>Videos entdecken</app-button>
  </app-teaser-slider-empty-message>
</div>
<ng-template #noList>
  <div *ngIf="bookmarksListError$ | async as bookmarksListError; else loading">
    {{ bookmarksListError }}
  </div>
  <ng-template #loading><app-ye-loading-spinner></app-ye-loading-spinner></ng-template>
</ng-template>